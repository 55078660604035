import { FiEdit2 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import * as Yup from 'yup';
import ProfileAvatar from '../../../components/ProfileAvatar';

export const teamColumns = (actions: any) => [
  {
    name: 'Team Members',
    cell: (row: any) => [
      <ProfileAvatar
        key={row.id}
        firstName={row.firstName}
        lastName={row.lastName}
        profileImage={row.profileImage}
      />
    ]
  },
  {
    name: 'Email',
    selector: (row: any) => row?.email
  },
  {
    name: 'Job Title',
    selector: (row: any) => row?.jobTitle
  },
  {
    name: 'Department',
    selector: (row: any) => row.department?.name
  },
  {
    name: 'Actions',
    type: 'actions',
    cell: (row: any) => [
      <>
        <div className='flex flex-row space-x-2'>
          <button
            onClick={() => actions.editAction(row)}
            className='cursor-pointer rounded-lg p-2 transition-all duration-300 hover:scale-110 hover:bg-[#EAECF0]'
            aria-label='Edit'
          >
            <FiEdit2 className='h-[20px] w-[20px] text-[#475467]' />
          </button>
        </div>
        {row.role !== 'admin' ? (
          <div className='flex flex-row space-x-2'>
            <button
              onClick={() => actions.deleteAction(row)}
              className='cursor-pointer rounded-lg p-2 transition-all duration-300 hover:scale-110 hover:bg-[#EAECF0]'
              aria-label='delete'
            >
              <HiOutlineTrash className='h-[20px] w-[20px] text-[#475467]' />
            </button>
          </div>
        ) : (
          '---'
        )}
      </>
    ]
  }
];

export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .transform((value) => value.trim())
    .min(2, 'The first name is too short!')
    .required('First name is required'),
  lastName: Yup.string()
    .transform((value) => value.trim())
    .min(2, 'The last name is too short!')
    .required('Last name is required'),
  email: Yup.string()
    .transform((value) => value.trim())
    .email('Invalid email')
    .required('User email is required')
  //   department: Yup.string().required(`User's department is required`)
});
