import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import {
  LuBookOpen,
  LuBuilding2,
  LuCalendarPlus,
  LuPenLine
} from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ImageContentLoader from '../../components/CardImageContentLoader';
import PageHeader from '../../components/PageHeader';
import { PUBLICATION_STATUS, PUBLICATION_TYPE } from '../../constants/Status';
import { useAuth } from '../../providers/AuthProvider';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';
import '../../styles/responsiveness/styles.css';
import { getRandomUniqueColor } from '../../utils/ColorPicker';
import EventCard from '../events/eventCard';
import NewsfeedCard from './feedCard';

const HomePage: React.FC = () => {
  const { auth } = useAuth();
  const { listFeeds } = useSelector((state: RootState) => state);
  const { listEvents } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: states.listFeeds.constants.request,
      params: { status: PUBLICATION_STATUS.PUBLISH }
    });

    dispatch({
      type: states.listEvents.constants.request,
      params: {
        type: PUBLICATION_TYPE.UPCOMING
      }
    });
  }, []);

  return (
    <>
      <PageHeader
        title={`Welcome back, ${auth?.firstName}`}
        description='Explore recent activities and manage company content effortlessly.'
      />
      {/* Quick Actions Section */}
      <div>
        <h2 className='mb-4 border-b border-brand-50 py-4 text-lg font-semibold text-brand-500'>
          Quick actions
        </h2>
        <div className='grid gap-4 md:grid-cols-2'>
          {/* Add news feed post */}
          <Link to={'/news-feed'}>
            <button className='flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md'>
              <div className='flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50'>
                <LuPenLine className='h-6 w-6 text-brand-600' />
              </div>
              <div className='flex flex-col items-start'>
                <p className='text-sm font-medium text-brand'>
                  Add news feed post
                </p>
                <p className='text-xs font-light text-brand-200'>
                  Share updates with employees
                </p>
              </div>
            </button>
          </Link>
          {/* Create new event */}
          <Link to={'/events'}>
            <button className='flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md'>
              <div className='flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50'>
                <LuCalendarPlus className='h-6 w-6 text-brand-600' />
              </div>
              <div className='flex flex-col items-start'>
                <p className='text-sm font-medium text-brand'>
                  Create new event
                </p>
                <p className='text-xs font-light text-brand-200'>
                  Plan and schedule company events
                </p>
              </div>
            </button>
          </Link>
          {/* Create new newsletter */}
          <Link to={'/newsletters'}>
            <button className='flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md'>
              <div className='flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50'>
                <LuBookOpen className='h-6 w-6 text-brand-600' />
              </div>
              <div className='flex flex-col items-start'>
                <p className='text-sm font-medium text-brand'>
                  Create newsletter
                </p>
                <p className='text-xs font-light text-brand-200'>
                  Keep teams informed with a newsletter
                </p>
              </div>
            </button>
          </Link>

          {/* Add new subsidiary */}
          <Link to={'/subsidiaries'}>
            <button className='flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md'>
              <div className='flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50'>
                <LuBuilding2 className='h-6 w-6 text-brand-600' />
              </div>
              <div className='flex flex-col items-start'>
                <p className='text-sm font-medium text-brand'>
                  Add new subsidiary
                </p>
                <p className='text-xs font-light text-brand-200'>
                  Add new subsidiary details
                </p>
              </div>
            </button>
          </Link>
        </div>
      </div>

      {/* Recent Feed News Section */}
      <div className='xs:grid-cols-12 grid grid-cols-1 gap-0 sm:grid-cols-12 sm:gap-4 md:grid-cols-4'>
        <section className='homepage-card-tablet-layout col-span-3 mt-8'>
          <h2 className='mb-4 text-lg font-semibold text-brand-500'>
            Recent feed news
          </h2>

          {/* Recent newsfeeds cards */}
          <div className='xs:grid-cols-12 grid max-h-[440px] gap-4 overflow-y-auto sm:grid-cols-12 md:grid-cols-2'>
            {listFeeds.loading ? (
              <ImageContentLoader />
            ) : (
              ((listFeeds?.data as any[]) || []).map((item, index) => [
                <NewsfeedCard
                  key={index}
                  title={item.title || ''}
                  imageUrl={item.imageUrl || ''}
                  date={format(item?.updatedAt, 'dd MMM yyyy')}
                  description={item?.caption ?? ''}
                />
              ])
            )}
          </div>
        </section>

        <section className='homepage-card-tablet-layout col-span-1 mt-9 w-full gap-4'>
          <h2 className='mb-4 font-semibold text-brand-300'>Upcoming Events</h2>

          <div className='grid max-h-[440px] w-full gap-4 overflow-y-auto'>
            {' '}
            {!isEmpty(listEvents.data) ? (
              ((listEvents.data as any[]) || []).map((item, index) => [
                <EventCard
                  key={index}
                  leftBarColor={getRandomUniqueColor()}
                  eventName={item.title || ''}
                  location={item.location || ''}
                  month={item.date}
                  date={item.date}
                  startTime={item.startTime}
                  endTime={item.endTime}
                />
              ])
            ) : (
              <div className='mb-4 flex w-full rounded-lg border border-brand-50 bg-white p-4 shadow-md'>
                <div className='mr-4 mt-2 h-[80px] w-[6px] rounded-full bg-gradient-to-b from-gray to-brand-100'></div>
                <div className='flex w-full flex-col items-center justify-center text-center'>
                  <h1 className='py-2 text-sm font-semibold text-brand-300'>
                    No Upcoming Events
                  </h1>
                  <p className='mt-2 text-sm font-light text-brand-300'>
                    Stay tuned! Events will be displayed here once scheduled.
                  </p>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default HomePage;
