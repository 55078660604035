import { format } from 'date-fns';
import _ from 'lodash';
import * as Yup from 'yup';
import { ActionButtons } from '../../../components/ActionButtons';

export const newsfeedColumns = (actions: any) => [
  {
    name: 'Post Title',
    selector: (row: any) =>
      _.truncate(row?.title, { length: 15, omission: '...' }),
    style: {
      flex: '1'
    }
  },
  {
    name: 'Caption',
    selector: (row: any) =>
      _.truncate(row?.caption, { length: 150, omission: '...' }),
    style: {
      flex: '1'
    }
  },
  {
    name: 'Date created',
    selector: (row: any) => format(row?.createdAt, 'MMM dd, yyyy'),
    style: {
      flex: '1'
    }
  },
  {
    name: 'Actions',
    type: 'actions',
    cell: (row: any) => [
      <ActionButtons row={row} key={row?.id} actions={actions} />
    ]
  }
];

export const feedValidationSchema = Yup.object().shape({
  caption: Yup.string()
    .transform((value) => value.trim())
    .min(2, 'caption is too short')
    .required('Caption is required'),
  title: Yup.string()
    .transform((value) => value.trim())
    .min(2, 'Title is too short!')
    .required('Title is required')
});
