import PropTypes from 'prop-types';

interface FieldWrapperProps {
  key?: string;
  children?: React.ReactNode;
}

export const FieldWrapper: React.FC<FieldWrapperProps> = ({
  key,
  children
}) => (
  <div className='w-full rounded-lg bg-white p-5' key={key}>
    {children}
  </div>
);

FieldWrapper.propTypes = { key: PropTypes.string, children: PropTypes.any };
