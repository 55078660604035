import React from 'react';

interface PageHeaderProps {
  title: string;
  description?: string;
  className?: string;
  titleHeader?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  className = '',
  titleHeader = 'tex-2xl xs:text-xl sm:text-3xl'
}) => {
  return (
    <div className={`container ${className}`}>
      <div className='mb-1 flex'>
        <h1
          className={`py-1 ${titleHeader} font-semibold leading-9 text-brand`}
        >
          {title}
        </h1>
      </div>
      <p className='mx-1 mb-6 text-sm font-light leading-6 text-brand-500 sm:text-base'>
        {description}
      </p>
    </div>
  );
};

export default PageHeader;
