import React from 'react';
import { Outlet } from 'react-router-dom';
import MobileUserMenu from './MobileUserMenu.tsx';
import Sidebar from './SideBar.tsx';

const Layout: React.FC = () => {
  return (
    <div className='flex h-screen flex-col sm:flex-row'>
      <Sidebar />
      <MobileUserMenu />
      <main className='bg-gray-50 flex-1 overflow-y-auto'>
        <div className='p-6'>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Layout;
