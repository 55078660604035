import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import FileUploader from '../../components/ImageUploader';
import { useAuth } from '../../providers/AuthProvider';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';
import { userValidationSchema } from './columns/TeamColumn';

interface InitialValuesType {
  id: any;
  firstName: string;
  lastName: string;
  about?: string;
  email: string;
  jobTitle: string;
  department: { label: string; value: string };
  profileImage: null | string;
  role: string;
}

const AddNewMemberForm = ({
  initialValues,
  handleCloseForm
}: {
  initialValues: InitialValuesType;
  handleCloseForm: () => void;
}) => {
  const { auth } = useAuth();
  const { createUsers } = useSelector((state: RootState) => state);
  const { listDepartments } = useSelector((state: RootState) => state);
  const { updateUsers } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch({
      type: states.listDepartments.constants.request,
      params: { searchKey: null }
    });
  }, []);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: userValidationSchema,
    onSubmit: (values) => {
      const { id, department, ...restValues } = values;

      const payload = {
        ...restValues,
        profileImage: previewUrls[0] ? previewUrls[0] : null,
        companyId: auth?.companyId,
        role: initialValues.role
      };

      if (id) {
        dispatch({
          type: states.updateUsers.constants.request,
          params: { id: id },
          payload: {
            ...payload,
            departmentId: department.value ? department.value : department
          }
        });
      } else {
        dispatch({
          type: states.createUsers.constants.request,
          payload: {
            ...payload,
            departmentId: department
          }
        });
      }
    }
  });

  const [previewUrls, setPreviewUrls] = useState<string[]>([
    initialValues.profileImage ? initialValues.profileImage : ''
  ]);

  const handlePreviewUrlsChange = (urls: string[]) => {
    setPreviewUrls(urls);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className='m-auto max-w-full rounded-lg bg-[#F8F9FA] p-8 shadow-md'
    >
      <div className='space-y-6'>
        {/* Full Name Section */}
        <div className='flex flex-col space-y-2'>
          <label
            htmlFor='firstName'
            className='text-sm font-semibold text-[#344054]'
          >
            Full Name <span className='text-rose-600'>*</span>
          </label>
          <div className='flex w-full space-x-4'>
            <div className='w-full'>
              <input
                id='firstName'
                name='firstName'
                type='text'
                placeholder='First name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                className={`text-gray-700 h-[40px] w-full rounded-md border px-3 text-sm placeholder:text-neutral-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
                  formik.errors.firstName && formik.touched.firstName
                    ? 'border-red-500 focus:ring-red-500'
                    : 'border-slate-200 focus:ring-indigo-500'
                }`}
              />
              {formik.errors.firstName && formik.touched.firstName && (
                <p className='text-xs text-red-500'>
                  {formik.errors.firstName}
                </p>
              )}
            </div>
            <div className='w-full'>
              <input
                id='lastName'
                name='lastName'
                type='text'
                placeholder='Last name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                className={`text-gray-700 h-[40px] w-full rounded-md border px-3 text-sm placeholder:text-neutral-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
                  formik.errors.lastName && formik.touched.lastName
                    ? 'border-red-500 focus:ring-red-500'
                    : 'border-slate-200 focus:ring-indigo-500'
                }`}
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <p className='text-xs text-red-500'>{formik.errors.lastName}</p>
              )}
            </div>
          </div>
        </div>

        {/* Email Section */}
        <div className='flex w-3/6 flex-col space-y-2'>
          <label
            htmlFor='email'
            className='text-sm font-semibold text-[#344054]'
          >
            Email <span className='text-rose-600'>*</span>
          </label>
          <input
            id='email'
            name='email'
            type='email'
            placeholder='Email'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={`text-gray-700 h-[40px] rounded-md border px-3 text-sm placeholder:text-neutral-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
              formik.errors.email && formik.touched.email
                ? 'border-red-500 focus:ring-red-500'
                : 'border-slate-200 focus:ring-indigo-500'
            }`}
          />
          {formik.errors.email && formik.touched.email && (
            <p className='text-xs text-red-500'>{formik.errors.email}</p>
          )}
        </div>

        <div className='flex h-1/3 w-full space-x-1'>
          <div className='w-1/3'>
            <label
              htmlFor='about'
              className='text-sm font-semibold text-[#344054]'
            >
              Your photo
            </label>
            <br />
            <label
              htmlFor='about'
              className='text-sm font-light text-[#344054]'
            >
              This will be displayed on their profile.
            </label>
          </div>
          <div className='w-full'>
            <FileUploader
              initialValue={initialValues.profileImage}
              title=''
              onPreviewUrlsChange={handlePreviewUrlsChange}
            />
          </div>
        </div>
        {/* About Section */}
        <div className='flex flex-col space-y-2'>
          <label
            htmlFor='about'
            className='text-sm font-semibold text-[#344054]'
          >
            About
          </label>
          <textarea
            id='about'
            name='about'
            placeholder='Summarize about the user profile in short'
            onChange={formik.handleChange}
            value={formik.values.about}
            className='text-gray-700 min-h-[100px] rounded-md border border-slate-200 px-3 text-sm placeholder:text-neutral-400 focus:shadow-lg focus:outline-none focus:ring-1 focus:ring-indigo-500'
          />
        </div>

        {/* Job Title and Department Section */}
        <div className='flex w-3/6 flex-col space-y-4'>
          <div className='flex items-center space-x-4'>
            <label
              htmlFor='jobTitle'
              className='px text-sm font-semibold text-[#344054]'
            >
              Job Title
            </label>
            <input
              id='jobTitle'
              name='jobTitle'
              type='text'
              placeholder='Add job title'
              onChange={formik.handleChange}
              value={formik.values.jobTitle}
              className='text-gray-700 h-[40px] w-full rounded-md border border-slate-200 px-3 text-sm placeholder:text-neutral-400 focus:shadow-lg focus:outline-none focus:ring-1 focus:ring-indigo-500'
            />
          </div>
          <div className='flex items-center space-x-4'>
            <label
              htmlFor='department'
              className='flex text-sm font-semibold text-[#344054]'
            >
              Department <span className='px-1 text-rose-600'>*</span>
            </label>
            <select
              id='department'
              name='department'
              required={true}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.department.value}
              className={`text-gray-700 h-[40px] w-full rounded-md border px-3 text-sm placeholder:text-neutral-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
                formik.errors.department && formik.touched.department
                  ? 'border-red-500 focus:ring-red-500'
                  : 'border-slate-200 focus:ring-indigo-500'
              }`}
            >
              <option value='' disabled>
                Select a department
              </option>
              {((listDepartments?.data as any[]) ?? []).map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Buttons */}
        <div className='flex justify-end space-x-4'>
          <button
            type='button'
            onClick={handleCloseForm}
            className='rounded-lg border border-brand-50 bg-none px-4 py-2 text-brand transition duration-300 hover:bg-slate-100'
          >
            Cancel
          </button>
          <button
            type='submit'
            className='rounded-lg bg-brand px-4 py-2 text-white transition duration-300 hover:bg-brand-400'
          >
            {createUsers.loading || updateUsers.loading ? (
              <div className='h-6 w-6 animate-spin rounded-full border-b-2 border-current' />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddNewMemberForm;
