import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';
import '../../styles/responsiveness/styles.css';
import { departmentValidationSchema } from './columns/DepartmentColumn';

interface InitialValuesType {
  id: any;
  name: string;
  description?: string;
  website?: string;
  company: { label: string; value: string };
  isEditClicked?: boolean;
}

const DepartmentForm = ({
  initialValues,
  handleCloseForm
}: {
  initialValues: InitialValuesType;
  handleCloseForm: () => void;
}) => {
  const { createDepartments } = useSelector((state: RootState) => state);
  const { listCompanies } = useSelector((state: RootState) => state);
  const { updateDepartments } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch({
      type: states.listCompanies.constants.request,
      params: { searchKey: null }
    });
  }, []);

  const companyOptions =
    ((listCompanies.data as any[]) || []).map(
      (company: { id: string; name: string }) => ({
        value: company.id,
        label: company.name
      })
    ) || [];

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: departmentValidationSchema,
    onSubmit: (values) => {
      const { id, company, ...restValues } = values; // Remove `company` from `values`
      const payload = {
        ...restValues,
        companyId: company?.value || null // Use `company.value` as `companyId`
      };
      if (id) {
        dispatch({
          type: states.updateDepartments.constants.request,
          params: { id: values?.id },
          payload
        });
      } else {
        dispatch({
          type: states.createDepartments.constants.request,
          payload
        });
      }
    }
  });

  // Handle Select Change
  const handleSelectChange =
    (field: string) =>
    (selectedOption: { value: string; label: string } | null) => {
      formik.setFieldValue(field, selectedOption); // Update any field dynamically
    };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div key='name' className='mb-5 rounded-[10px] bg-white p-5'>
          <label
            htmlFor='name'
            className='text-gray-700 block text-sm font-medium'
          >
            {' '}
            Name <span className='text-red-500'>*</span>
          </label>
          <input
            id='name'
            name='name'
            type='text'
            placeholder='Enter department name'
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            className={`border-gray-300 mt-1 block w-1/2 rounded-lg border px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand ${
              formik.errors.name && formik.touched.name
                ? 'border-red-500 focus:ring-red-500'
                : 'border-slate-200 focus:ring-indigo-500'
            }`}
          />
          {formik.errors.name && formik.touched.name && (
            <p className='text-xs text-red-500'>{formik.errors.name}</p>
          )}
        </div>
        <div key='description' className='mb-5 rounded-[10px] bg-white p-5'>
          <label
            htmlFor='description'
            className='text-gray-700 block text-sm font-medium'
          >
            {' '}
            Description
          </label>
          <textarea
            id='description'
            name='description'
            placeholder='Enter department description'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            className='border-gray-300 mt-1 block w-full rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500'
          />
        </div>
        <div key='company' className='mb-5 rounded-[10px] bg-white p-5'>
          <label
            htmlFor='company'
            className='text-gray-700 block text-sm font-medium'
          >
            {' '}
            Company <span className='text-red-500'>*</span>
          </label>
          <Select
            id='company'
            name='company'
            options={companyOptions}
            placeholder='Select the company'
            onChange={handleSelectChange('company')}
            value={formik.values.company} // Set the value from formik
            classNamePrefix='react-select'
            className={`mt-1 block w-1/2 rounded-lg text-sm ${
              formik.errors.company && formik.touched.company
                ? 'border-red-500 focus:ring-red-500'
                : 'border-slate-200 focus:ring-indigo-500'
            }`}
          />
          {formik.errors.company?.value && formik.touched.company && (
            <p className='text-xs text-red-500'>
              {formik.errors.company?.value}
            </p>
          )}
        </div>
        <div className='mt-6 flex w-full justify-center sm:justify-end'>
          <div className='btn-group-tablet-width my-4 flex w-full gap-3 text-sm font-semibold sm:w-1/4'>
            <button
              onClick={handleCloseForm}
              className='w-full rounded-lg border border-brand-50 bg-none p-2 text-brand transition duration-300 ease-in-out hover:bg-slate-100'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='flex w-full justify-center rounded-lg border border-brand-200 bg-brand p-2 text-white transition duration-300 ease-in-out hover:bg-brand-400'
            >
              {createDepartments.loading || updateDepartments.loading ? (
                <div className='h-6 w-6 animate-spin rounded-full border-b-2 border-current' />
              ) : initialValues.isEditClicked ? (
                'Save Changes'
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DepartmentForm;
