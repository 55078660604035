import { Suspense } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Store } from 'redux';
import './App.css';
import Error from './components/Error.tsx';
import Spinner from './components/Spinner.tsx';
import { AuthProvider } from './providers/AuthProvider.tsx';
import MsalAuthProvider from './providers/MsalAuthProvider.tsx';
import { ToastProvider } from './providers/ToastProvider.tsx';
import MainLayout from './routes';

interface AppProps {
  store: Store;
}

function App({ store }: Readonly<AppProps>) {
  return (
    <BrowserRouter>
      <ErrorBoundary fallback={<Error>{'Something went wrong!'}</Error>}>
        <Suspense fallback={<Spinner />}>
          <ToastProvider>
            <MsalAuthProvider>
              <AuthProvider>
                <Provider store={store}>
                  <MainLayout />
                </Provider>
              </AuthProvider>
            </MsalAuthProvider>
          </ToastProvider>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired
};

export default App;
