import React from 'react';
import _ from 'lodash';

interface NewsfeedCardProps {
  title: string;
  date: string;
  description: string;
  imageUrl?: string;
}

const NewsfeedCard: React.FC<NewsfeedCardProps> = ({
  title,
  date,
  description,
  imageUrl
}) => {
  return (
    <div className='rounded-lg border border-brand-50 p-4 hover:cursor-text hover:shadow-md'>
      <img
        src={
          !_.isEmpty(imageUrl)
            ? imageUrl
            : '/assets/image-not-available-avatar.svg'
        }
        alt={title}
        className='mb-2 h-64 w-full rounded-lg object-cover'
      />
      <h3 className='py-1 font-medium leading-6 text-brand'>{title}</h3>
      <p className='py-1 text-sm font-medium leading-6 text-brand text-brand-400'>
        {date}
      </p>
      <p className='text-sm font-light leading-6 text-brand-300'>
        {_.truncate(description || '', { length: 200, omission: '....' })}
      </p>
    </div>
  );
};

export default NewsfeedCard;
