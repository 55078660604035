import { IoImageOutline } from 'react-icons/io5';

const ImageContentLoader = () => {
  return (
    <div className='grid-row-2 mx-auto grid w-full max-w-sm animate-pulse rounded-md border border-none p-4 shadow'>
      <div className='flex h-[180px] w-full items-center justify-center rounded-lg bg-brand-50'>
        <IoImageOutline size={50} className='stroke-brand-200 stroke-2' />{' '}
      </div>
      <div className='col-span-1 my-3 h-2 w-[30px] rounded bg-brand-100'></div>
      <div className='flex space-x-4'>
        <div className='flex-1 space-y-2 py-1'>
          <div className='h-2 rounded bg-brand-100'></div>
          <div className='space-y-2'>
            <div className='grid grid-cols-3 gap-4'>
              <div className='col-span-2 h-2 rounded bg-brand-100'></div>
            </div>
            <div className='h-2 rounded bg-brand-100'></div>
            <div className='col-span-2 h-2 rounded bg-brand-100'></div>
            <div className='col-span-2 h-2 w-4/5 rounded bg-brand-100'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageContentLoader;
