import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reducers from '../reducers';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const storeConfig = () => {
  const middleware = [sagaMiddleware];

  if (import.meta.env.MODE === 'development') {
    middleware.push(logger as any); // Add logger only in development mode
  }

  const store = configureStore({
    reducer: reducers, // Add reducers
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: false }).concat(middleware), // Disable thunk and add custom middleware
    devTools: import.meta.env.MODE === 'development' // Enable Redux DevTools in development mode
  });

  // Run the root saga
  sagaMiddleware.run(rootSaga);

  return store;
};

export default storeConfig;
