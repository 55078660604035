import _ from 'lodash';
import * as Yup from 'yup';
import { ActionButtons } from '../../../components/ActionButtons';

export const eventColumns = (actions: any) => [
  {
    name: 'Event Title',
    selector: (row: any) =>
      _.truncate(row?.title, { length: 15, omission: '...' })
  },
  {
    name: 'Event Date',
    selector: (row: any) =>
      new Date(row?.date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
  },
  {
    name: 'Time',
    selector: (row: any) => `${row?.startTime} - ${row?.endTime}`
  },
  {
    name: 'Actions',
    type: 'actions',
    cell: (row: any) => [
      <ActionButtons row={row} key={row.id} actions={actions} />
    ]
  }
];

export const eventValidationSchema = Yup.object().shape({
  title: Yup.string()
    .transform((value) => value.trim())
    .min(2, 'The title is too short!')
    .required('Title is required')
});
