import React from 'react';
import clsx from 'clsx';

type ButtonProps = {
  label: string | React.ReactNode;
  onClick?: () => void;
  variant?: 'primary' | 'secondary' | 'danger';
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  variant = 'primary',
  isLoading = false,
  disabled = false,
  className
}) => {
  const baseStyles = `flex items-center h-[40px] justify-center px-4 py-2 text-sm font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2`;
  const variants = {
    primary: `bg-brand hover:bg-brand-600 text-white focus:ring-brand-300 disabled:bg-brand-400`,
    secondary: `bg-gray-light hover:bg-gray text-gray-dark focus:ring-gray disabled:bg-gray-300`,
    danger: `bg-error hover:bg-red-700 text-white focus:ring-red-300 disabled:bg-red-400`
  };

  const stateStyles =
    disabled || isLoading ? `cursor-not-allowed opacity-60` : `cursor-pointer`;

  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={clsx(baseStyles, variants[variant], stateStyles, className)}
    >
      {isLoading ? (
        <span className='loader h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent'></span>
      ) : (
        label
      )}
    </button>
  );
};

export default Button;
