import React from 'react';
import { FaArrowLeft } from 'react-icons/fa6';

type BackButtonProps = {
  handleclose: () => void;
};
const BackButton: React.FC<BackButtonProps> = ({ handleclose }) => {
  const handleOnClick = (url: void) => {
    console.log(url);
  };
  return (
    <div className='mb-6'>
      <button
        onClick={() => handleOnClick(handleclose())}
        className='flex items-center space-x-2'
      >
        <FaArrowLeft />
        <span>Back</span>
      </button>
    </div>
  );
};

export default BackButton;
