import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FiEdit2 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from '../../components/ConfirmActionModal';
import PageHeader from '../../components/PageHeader';
import SearchBar from '../../components/SearchBar';
import { PUBLICATION_STATUS, PUBLICATION_TYPE } from '../../constants/Status';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';
import { tableSkeletonLoaderColumns } from '../../utils/common/TableLoader';
import { useResetState } from '../../utils/hooks/useResetState';
import { customStyles } from '../team';
import EventForm from './EventForm';
import { eventColumns } from './columns/EventColumn';

const EventsPage: React.FC = () => {
  const { listEvents } = useSelector((state: RootState) => state);
  const { deleteEvents } = useSelector((state: RootState) => state);
  const { updateEvents } = useSelector((state: RootState) => state);
  const { createEvents } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [isPastEvent, setIsPastEvent] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: null,
    title: '',
    imageUrl: '',
    date: '',
    startTime: '',
    endTime: '',
    aboutEvent: '',
    eventAgenda: [],
    eventImagesUrl: [],
    externalImageUrl: '',
    location: ''
  });

  useEffect(() => {
    dispatch({
      type: states.listEvents.constants.request,
      params: {
        status:
          active === 1
            ? PUBLICATION_STATUS.PUBLISH
            : active === 2
              ? PUBLICATION_STATUS.DRAFT
              : null,
        type: active === 3 ? PUBLICATION_TYPE.PAST : PUBLICATION_TYPE.UPCOMING
      }
    });
  }, [active]);

  const editAction = (data: any) => {
    setInitialValues({
      id: data.id,
      title: data.title,
      imageUrl: data.imageUrl,
      date: data.date,
      startTime: data.startTime,
      endTime: data.endTime,
      aboutEvent: data.aboutEvent,
      eventAgenda: data.eventAgenda,
      eventImagesUrl: data.eventImagesUrl,
      externalImageUrl: data.externalImageUrl,
      location: data.location
    });
    setIsPastEvent(active === 3);
    setShowForm(true);
  };

  const deleteAction = (data: any) => {
    setDeleteItem(data.id);
    setShowDeleteModal(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setShowDeleteModal(false);
    setInitialValues({
      id: null,
      title: '',
      imageUrl: '',
      date: '',
      startTime: '',
      endTime: '',
      aboutEvent: '',
      eventAgenda: [],
      eventImagesUrl: [],
      externalImageUrl: '',
      location: ''
    });
    setDeleteItem(null);
    setActive(1);
  };

  const actions = [
    {
      icon: <FiEdit2 className='h-[20px] w-[20px] text-[#475467]' />,
      handler: editAction,
      label: 'Edit'
    },
    {
      icon: <HiOutlineTrash className='h-[20px] w-[20px] text-[#475467]' />,
      handler: deleteAction,
      label: 'Delete'
    }
  ];

  const handleDelete = () => {
    dispatch({
      type: states.deleteEvents.constants.request,
      params: {
        id: deleteItem,
        status:
          active === 1 ? PUBLICATION_STATUS.PUBLISH : PUBLICATION_STATUS.DRAFT,
        type:
          active === 3
            ? PUBLICATION_TYPE.PAST
            : active === 1
              ? PUBLICATION_TYPE.UPCOMING
              : null
      }
    });
  };

  const Tabs = () => (
    <div className='m-2 w-full sm:w-[40%]'>
      <ul
        className='relative flex list-none rounded-lg bg-[#F9FAFB] px-1.5 py-1.5'
        data-tabs='tabs'
      >
        {['Published', 'Drafts', 'Past Events'].map((tab, index) => {
          const tabIndex = index + 1;
          const isActive = active === tabIndex;

          return (
            <div
              key={tabIndex}
              className={`mx-1 w-[90%] rounded-lg px-3 py-1 transition-all duration-300 ${
                isActive ? 'bg-white shadow-md' : ''
              }`}
            >
              <li className='z-30 flex-auto text-center'>
                <button
                  className={`z-30 mb-0 flex w-full cursor-pointer items-center justify-center rounded-md border-0 bg-inherit px-0 py-2 text-sm font-medium transition-all ease-in-out ${
                    isActive ? 'font-semibold text-slate-800' : 'text-slate-600'
                  }`}
                  role='tab'
                  aria-selected={isActive}
                  onClick={() => {
                    setActive(tabIndex);
                  }}
                >
                  {tab}
                </button>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );

  const handleSearchSubmit = (data: string) => {
    dispatch({
      type: states.listEvents.constants.request,
      params: {
        status:
          active === 1
            ? PUBLICATION_STATUS.PUBLISH
            : active === 2
              ? PUBLICATION_STATUS.DRAFT
              : undefined,
        type: active === 3 ? PUBLICATION_TYPE.PAST : undefined,
        searchKey: data
      }
    });
  };

  useEffect(() => {
    if (createEvents.success || deleteEvents.success || updateEvents.success) {
      handleCloseForm();
    }
  }, [createEvents?.success, deleteEvents.success, updateEvents.success]);

  useResetState('createEvents', [createEvents.success as never]);
  useResetState('deleteEvents', [deleteEvents.success as never]);
  useResetState('updateEvents', [updateEvents.success as never]);

  return (
    <div>
      <DeleteModal
        isOpen={showDeleteModal}
        description='Are you sure you want to delete this item?'
        onConfirm={handleDelete}
        onCancel={handleCloseForm}
        loading={deleteEvents.loading}
      />
      <PageHeader
        title='Events'
        description='Create and manage events to share with your team'
      />
      {showForm && (
        <div className='my-3 flex items-center space-x-2 text-brand-500'>
          <button className='flex items-center' onClick={handleCloseForm}>
            <IoIosArrowRoundBack className='text-2xl' />
            <p className='text-normal font-semibold'>Back</p>
          </button>
        </div>
      )}

      <div className='rounded-[10px] border-[1px] border-solid border-[#EAECF0] bg-[#cccccc2b] p-4'>
        <div className='flex flex-row place-content-between'>
          <div>
            <h1 className='px-2 py-2 text-lg font-semibold leading-9 text-[#101828]'>
              {!showForm ? 'Events' : 'Create new events'}
            </h1>
            {showForm && (
              <p className='mx-2 mb-2 text-base font-normal text-[#475467]'>
                Enter all information needed to create a new event{' '}
              </p>
            )}
          </div>
          {!showForm && (
            <div className='flex items-center justify-center px-2'>
              <button
                className='rounded-lg bg-brand px-4 py-2 text-white'
                onClick={() => setShowForm(true)}
              >
                {' '}
                <div className='flex items-center'>
                  <span className='font-inter text-left text-base text-sm font-semibold'>
                    + Event
                  </span>
                </div>
              </button>
            </div>
          )}
        </div>

        {/* Divider between title and table */}
        <div className='mb-3 border-t border-[#EAECF0]'></div>
        {!showForm && (
          <>
            {' '}
            <div className='flex w-full flex-col items-center justify-between sm:flex-row'>
              <Tabs />{' '}
              <SearchBar
                placeholder='Search...'
                onSubmit={handleSearchSubmit}
              />
            </div>
            <div className='m-2 rounded-[10px] border-[1px] border-solid border-[#EAECF0]'>
              <DataTable
                columns={
                  listEvents.loading
                    ? tableSkeletonLoaderColumns()
                    : eventColumns(actions)
                }
                data={(listEvents?.data as any[]) ?? []}
                customStyles={customStyles}
                pagination={true}
                paginationPerPage={5}
                fixedHeader={true}
              />
            </div>
          </>
        )}

        {showForm && (
          <EventForm
            initialValues={initialValues}
            handleCloseForm={handleCloseForm}
            isPastEvent={isPastEvent}
          />
        )}
      </div>
    </div>
  );
};

export default EventsPage;
