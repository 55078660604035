import React, { useEffect, useState } from 'react';
import { IoIosMenu } from 'react-icons/io';
import { LuChevronDown, LuChevronRight } from 'react-icons/lu';
import { TbLogout } from 'react-icons/tb';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { sidebarItems } from './SideBar';

const MobileUserMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { auth, logout } = useAuth();

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Close menu on Esc key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeMenu();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <aside className='relative bg-brand text-xs font-normal text-white sm:hidden'>
      {/* Menu Button */}
      <div className='flex justify-between px-3 py-3 shadow-xl'>
        <div className='flex p-1'>
          <img
            className='h-10 w-10 rounded-full'
            src={auth?.profileImage || '/assets/user_avatar.png'}
            alt='User profile'
          />{' '}
          <div className='px-2'>
            <p className='font-semibold'>{`${auth?.firstName} ${auth?.lastName}`}</p>
            <p className='truncate text-sm'>{auth?.email}</p>
          </div>
        </div>
        <div className='flex items-center justify-center'>
          <button onClick={toggleMenu} className='focus:outline-none'>
            <IoIosMenu className='h-10 w-10 font-normal text-white' />
          </button>
        </div>
      </div>

      {/* Sliding Menu */}
      <div
        className={`animate-slide-in-left fixed inset-y-0 left-0 z-40 w-[60%] transform bg-brand shadow-lg transition-transform duration-300 ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {/* User Profile Header */}
        <div className='flex items-center space-x-3 px-3 py-3'>
          <img
            src='/cvl-logo-white.svg'
            alt='Logo'
            className='h-[4em] w-[10em]'
          />
        </div>

        {/* Menu Items */}
        <nav className='flex-1 px-4 py-4'>
          <ul className='space-y-2'>
            {sidebarItems.map((item) => (
              <li key={item.label} className='group'>
                {!item.subItems ? (
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      `flex items-center gap-3 rounded-lg p-2 ${
                        isActive ? 'bg-brand-300' : 'hover:bg-brand-400'
                      }`
                    }
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </NavLink>
                ) : (
                  <details className='group'>
                    <summary className='flex cursor-pointer items-center justify-between rounded-lg p-2 hover:bg-brand-300 [&::-webkit-details-marker]:hidden'>
                      <div className='flex items-center gap-3'>
                        {item.icon}
                        <span>{item.label}</span>
                      </div>
                      <div className='transition-transform duration-300 group-open:rotate-180'>
                        <LuChevronRight className='h-5 w-5 opacity-60 group-open:hidden' />
                        <LuChevronDown className='hidden h-5 w-5 opacity-60 group-open:block' />
                      </div>
                    </summary>
                    <ul className='ml-4 space-y-1'>
                      {item.subItems.map((subItem) => (
                        <li key={subItem.label}>
                          <NavLink
                            to={subItem.path}
                            className={({ isActive }) =>
                              `flex items-center gap-3 rounded-lg p-2 ${
                                isActive ? 'bg-brand-300' : 'hover:bg-brand-400'
                              }`
                            }
                          >
                            {subItem.icon}
                            <span>{subItem.label}</span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </details>
                )}
              </li>
            ))}
            <li
              key='signout-btn'
              className='flex w-full border-t border-brand-300 px-2 py-4'
            >
              <button
                onClick={logout}
                className='flex w-full items-center space-x-6 transition hover:text-brand-300 focus:outline-none'
                aria-label='Logout'
              >
                <span>Signout</span>
                <div>
                  <TbLogout size={20} className='mx-14 h-5 w-10 sm:mx-16' />
                </div>
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* Overlay */}
      {isMenuOpen && (
        <div
          className='fixed inset-0 z-30 bg-black bg-opacity-50'
          onClick={toggleMenu}
        ></div>
      )}
    </aside>
  );
};

export default MobileUserMenu;
