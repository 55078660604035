import { format } from 'date-fns';
import _ from 'lodash';
import { ActionButtons } from '../../../components/ActionButtons';

export const newsletterColumns = (actions: any) => [
  {
    name: 'Title',
    selector: (row: any) =>
      _.truncate(row?.title, { length: 15, omission: '...' })
  },
  {
    name: 'Description',
    selector: (row: any) =>
      _.truncate(row?.description, {
        length: 150,
        omission: '...'
      })
  },
  {
    name: 'Date Created',
    selector: (row: any) => format(row?.createdAt, 'MMM dd, yyyy')
  },
  {
    name: 'Actions',
    type: 'actions',
    cell: (row: any) => [
      row.role !== 'admin' ? (
        <ActionButtons row={row} key={row.id} actions={actions} />
      ) : (
        '---'
      )
    ]
  }
];
