import React from 'react';
import { MdErrorOutline } from 'react-icons/md';

export default function Error({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className='flex items-center text-sm font-semibold text-red-500'>
        <MdErrorOutline className='mr-2 h-10 w-10' />
        {children}
      </div>
    </>
  );
}
