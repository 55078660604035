import {
  ElementRef,
  ReactNode,
  createContext,
  forwardRef,
  useContext,
  useState
} from 'react';
import * as RadixToast from '@radix-ui/react-toast';
import { AnimatePresence, motion } from 'framer-motion';
import { IoCloseCircleOutline } from 'react-icons/io5';

// Define types for toast messages
type ToastType = 'success' | 'error';

interface ToastMessage {
  id: string;
  type: ToastType;
  text: string;
}

// Context for showing toast
const ToastContext = createContext<{
  showToast: (type: ToastType, text: string) => void;
}>({
  showToast: () => {
    throw new Error(
      "You can't call showToast() outside of a <ToastProvider> – add it to your tree."
    );
  }
});

export function useToast() {
  return useContext(ToastContext);
}

// Toast Provider
export function ToastProvider({ children }: { children: ReactNode }) {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  function showToast(type: ToastType, text: string) {
    setMessages((toasts) => [
      ...toasts,
      {
        id: window.crypto.randomUUID(),
        text,
        type
      }
    ]);
  }

  return (
    <RadixToast.Provider>
      <ToastContext.Provider value={{ showToast }}>
        {children}
      </ToastContext.Provider>

      <AnimatePresence mode='popLayout'>
        {messages.map((toast) => (
          <Toast
            key={toast.id}
            text={toast.text}
            type={toast.type}
            onClose={() =>
              setMessages((toasts) => toasts.filter((t) => t.id !== toast.id))
            }
          />
        ))}
      </AnimatePresence>

      <RadixToast.Viewport className='fixed right-4 top-4 flex w-80 flex-col-reverse gap-3 max-sm:top-20' />
    </RadixToast.Provider>
  );
}

// Toast Component
const Toast = forwardRef<
  ElementRef<typeof RadixToast.Root>,
  {
    onClose: () => void;
    text: string;
    type: ToastType;
  }
>(function Toast({ onClose, text, type }, forwardedRef) {
  const width = 320;
  const margin = 16;

  // Determine toast background color based on type
  const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';

  return (
    <RadixToast.Root
      ref={forwardedRef}
      asChild
      forceMount
      onOpenChange={onClose}
      duration={10000}
    >
      <motion.li
        layout
        initial={{ x: width + margin }}
        animate={{ x: 0 }}
        exit={{
          opacity: 0,
          zIndex: -1,
          transition: {
            opacity: {
              duration: 0.2
            }
          }
        }}
        transition={{
          type: 'spring',
          mass: 1,
          damping: 30,
          stiffness: 200
        }}
        style={{ width, WebkitTapHighlightColor: 'transparent' }}
      >
        <div
          className={`border-gray-600 flex items-center justify-between overflow-hidden whitespace-nowrap rounded-lg border ${bgColor} text-sm text-white shadow-sm backdrop-blur`}
        >
          <RadixToast.Description className='truncate p-4'>
            {text}
          </RadixToast.Description>
          <RadixToast.Close className='border-gray-600/50 text-gray-500 hover:bg-gray-600/30 hover:text-gray-300 border-l p-4 transition active:text-white'>
            <IoCloseCircleOutline className='h-5 w-5' />
          </RadixToast.Close>
        </div>
      </motion.li>
    </RadixToast.Root>
  );
});
