import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FiEdit2 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from '../../components/ConfirmActionModal';
import PageHeader from '../../components/PageHeader';
import SearchBar from '../../components/SearchBar';
import { PUBLICATION_STATUS } from '../../constants/Status';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';
import { tableSkeletonLoaderColumns } from '../../utils/common/TableLoader';
import { customStyles } from '../team';
import AddNewsletterForm from './addNewsletterForm';
import { newsletterColumns } from './columns/newsletterColumn';

const NewsLetterPage: React.FC = () => {
  const listNewsletter = useSelector(
    (state: RootState) => state.listNewsletter
  );
  const deleteNewsletter = useSelector(
    (state: RootState) => state.deleteNewsletter
  );
  const createNewsletter = useSelector(
    (state: RootState) => state.createNewsletter
  );

  const updateNewsletter = useSelector(
    (state: RootState) => state.updateNewsletter
  );

  const dispatch = useDispatch();

  const [active, setActive] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [initialValue, setInitialValue] = useState({
    id: null,
    title: '',
    mainDescription: '',
    mainImage: '',
    departmentId: null,
    descriptionImage: '',
    descriptionTitle: '',
    sections: []
  });

  useEffect(() => {
    dispatch({
      type: states.listNewsletter.constants.request,
      params: {
        status:
          active === 1 ? PUBLICATION_STATUS.PUBLISH : PUBLICATION_STATUS.DRAFT
      }
    });
  }, [active]);

  const handleSearchSubmit = (data: any) => {
    dispatch({
      type: states.listNewsletter.constants.request,
      params: {
        searchKey: data,
        status:
          active === 1 ? PUBLICATION_STATUS.PUBLISH : PUBLICATION_STATUS.DRAFT
      }
    });
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setShowDeleteModal(false);
    setInitialValue({
      id: null,
      title: '',
      mainDescription: '',
      mainImage: '',
      departmentId: null,
      descriptionImage: '',
      descriptionTitle: '',
      sections: []
    });
  };

  const editAction = (data: any) => {
    setInitialValue({
      id: data.id,
      title: data.title,
      mainDescription: data.description,
      mainImage: data.imageUrl,
      departmentId: data.departmentId,
      descriptionImage: data.descriptionImage,
      descriptionTitle: data.descriptionTitle,
      sections: data.sections.map((section: any) => ({
        secTitle: section.title,
        description: section.description,
        image: section.imageUrl
      }))
    });
    setShowForm(true);
  };

  const deleteAction = (data: any) => {
    setDeleteItem(data.id);
    setShowDeleteModal(true);
  };

  const Tabs = () => (
    <div className='m-2 w-full rounded-lg sm:w-[30%]'>
      <ul
        className='relative flex list-none rounded-lg bg-[#F9FAFB] px-1.5 py-1.5'
        data-tabs='tabs'
      >
        {['Published', 'Drafts'].map((tab, index) => {
          const tabIndex = index + 1;
          const isActive = active === tabIndex;

          return (
            <div
              key={tabIndex}
              className={`w-[90%] rounded-lg px-3 py-1 transition-all duration-300 ${
                isActive ? 'bg-white shadow-md' : ''
              }`}
            >
              <li className='z-30 flex-auto text-center'>
                <button
                  className={`z-30 mb-0 flex w-full cursor-pointer items-center justify-center rounded-md border-0 bg-inherit px-0 py-2 text-sm transition-all ease-in-out ${
                    isActive ? 'font-semibold text-slate-800' : 'text-slate-600'
                  }`}
                  role='tab'
                  aria-selected={isActive}
                  onClick={() => setActive(tabIndex)}
                >
                  {tab}
                </button>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );

  const handleConfirmDelete = () => {
    dispatch({
      type: states.deleteNewsletter.constants.request,
      params: {
        id: deleteItem,
        status:
          active === 1 ? PUBLICATION_STATUS.PUBLISH : PUBLICATION_STATUS.DRAFT
      }
    });
  };

  const actions = [
    {
      icon: <FiEdit2 className='h-[20px] w-[20px] text-[#475467]' />,
      handler: editAction,
      label: 'Edit'
    },
    {
      icon: <HiOutlineTrash className='h-[20px] w-[20px] text-[#475467]' />,
      handler: deleteAction,
      label: 'Delete'
    }
  ];

  useEffect(() => {
    if (
      createNewsletter.success ||
      deleteNewsletter.success ||
      updateNewsletter.success
    ) {
      dispatch({
        type: states.createNewsletter.constants.reset
      });
      dispatch({
        type: states.deleteNewsletter.constants.reset
      });
      dispatch({
        type: states.updateNewsletter.constants.reset
      });
      handleCloseForm();
    }
  }, [
    createNewsletter?.success,
    deleteNewsletter.success,
    updateNewsletter.success
  ]);

  return (
    <div>
      <DeleteModal
        isOpen={showDeleteModal}
        description='Are you sure you want to delete this item?'
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseForm}
        loading={deleteNewsletter.loading}
      />

      <PageHeader
        title='Newsletter'
        description='Create and manage news letters to keep your team updated.'
      />
      {showForm && (
        <div className='mb-8 flex items-center space-x-2 text-brand-500'>
          <button className='flex items-center' onClick={handleCloseForm}>
            <IoIosArrowRoundBack className='text-2xl' />
            <p className='text-normal font-semibold'>Back</p>
          </button>
        </div>
      )}
      <div className='rounded-lg border-[1px] border-solid border-[#EAECF0] bg-[#cccccc2b] px-2'>
        <div className='flex flex-row place-content-between'>
          <div>
            <h1 className='px-0.5 py-3 text-sm font-semibold leading-9 text-[#101828] sm:px-2 sm:py-5 sm:text-base sm:text-xl'>
              NewsLetters
            </h1>
          </div>
          {!showForm && (
            <div className='flex items-center justify-center px-1 sm:px-2'>
              <button
                className='rounded-lg bg-brand px-3 py-2 text-white sm:px-4 sm:py-2'
                onClick={() => setShowForm(true)}
              >
                {' '}
                <div className='flex items-center'>
                  <span className='font-inter text-left text-xs font-semibold sm:text-sm'>
                    + New newsletter
                  </span>
                </div>
              </button>
            </div>
          )}
        </div>

        {/* Divider between title and table */}
        <div className='mb-3 border-t border-[#EAECF0]'></div>
        {!showForm && (
          <>
            <div className='flex w-full flex-col items-center justify-between sm:flex-row'>
              <Tabs />
              <SearchBar
                placeholder='Search...'
                onSubmit={handleSearchSubmit}
              />
            </div>
            <div className='m-2 rounded-[10px] border-[1px] border-solid border-[#EAECF0]'>
              <DataTable
                columns={
                  listNewsletter.loading
                    ? tableSkeletonLoaderColumns()
                    : newsletterColumns(actions)
                }
                data={(listNewsletter?.data as any[]) ?? []}
                pagination={true}
                paginationPerPage={5}
                fixedHeader={true}
                customStyles={customStyles}
              />
            </div>
          </>
        )}

        {showForm && (
          <AddNewsletterForm
            initialValues={initialValue}
            handleCloseForm={handleCloseForm}
          />
        )}
      </div>
    </div>
  );
};

export default NewsLetterPage;
