import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FiEdit2 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useResetState } from '../..//utils/hooks/useResetState';
import BackButton from '../../components/BackButton';
import DeleteModal from '../../components/ConfirmActionModal';
import PageHeader from '../../components/PageHeader';
import SearchBar from '../../components/SearchBar';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';
import { customStyles } from '../team';
import DepartmentForm from './DepartmentForm';
import { departmentColumns } from './columns/DepartmentColumn';

export default function DepartmentPage() {
  const { listDepartments } = useSelector((state: RootState) => state);
  const { createDepartments } = useSelector((state: RootState) => state);
  const { deleteDepartments } = useSelector((state: RootState) => state);
  const { updateDepartments } = useSelector((state: RootState) => state);

  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteItem, setDeleteItem] = useState(null);
  const [initialValues, setInitialValues] = useState({
    id: null,
    name: '',
    description: '',
    company: { label: '', value: '' },
    isEditClicked: false
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: states.listDepartments.constants.request,
      params: { searchKey: null }
    });
  }, []);

  //SET USERDATA IN THE FORM
  const editAction = (data: any) => {
    setInitialValues({
      id: data?.id,
      name: data?.name,
      description: data?.description,
      company: {
        label: data?.company?.name,
        value: data?.company?.id
      },
      isEditClicked: true
    });
    setShowForm(true);
  };

  const deleteAction = (data: any) => {
    setDeleteItem(data.id);
    setShowDeleteModal(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setInitialValues({
      id: null,
      name: '',
      description: '',
      company: { label: '', value: '' },
      isEditClicked: false
    });
    dispatch({
      type: states.listDepartments.constants.request,
      params: { searchKey: null }
    });
  };
  const actions = [
    {
      icon: <FiEdit2 className='h-[20px] w-[20px] text-[#475467]' />,
      handler: editAction,
      label: 'Edit'
    },
    {
      icon: <HiOutlineTrash className='h-[20px] w-[20px] text-[#475467]' />,
      handler: deleteAction,
      label: 'Delete'
    }
  ];

  const handleDelete = () => {
    dispatch({
      type: states.deleteDepartments.constants.request,
      params: { id: deleteItem }
    });
  };
  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };
  useEffect(() => {
    if (
      createDepartments.success ||
      deleteDepartments.success ||
      updateDepartments.success
    ) {
      handleCloseForm();
    }
  }, [
    createDepartments?.success,
    deleteDepartments.success,
    updateDepartments.success
  ]);

  useResetState('createDepartments', [createDepartments.success as never]);
  useResetState('updateDepartments', [updateDepartments.success as never]);
  useResetState('deleteDepartments', [deleteDepartments.success as never]);

  const handleSearchSubmit = (data: string) => {
    dispatch({
      type: states.listDepartments.constants.request,
      params: { searchKey: data }
    });
  };

  return (
    <>
      <DeleteModal
        isOpen={showDeleteModal}
        description='Are you sure you want to delete this department?'
        onConfirm={handleDelete}
        onCancel={handleCloseModal}
        loading={deleteDepartments.loading}
      />
      <PageHeader
        title='Company Department'
        description='Add department information requires below'
      />
      {showForm ? <BackButton handleclose={handleCloseForm} /> : <></>}
      <div className='rounded-[10px] border-[1px] border-solid border-[#EAECF0] bg-[#cccccc2b] p-4'>
        <div className='flex flex-row place-content-between'>
          <div>
            <h1 className='px-0.5 py-3 text-sm font-semibold leading-9 text-[#101828] sm:px-2 sm:py-5 sm:text-base sm:text-xl'>
              Departments
            </h1>
          </div>

          {!showForm && (
            <div className='flex items-center justify-center px-2'>
              <button
                className='rounded-lg bg-brand px-3 py-2 text-white sm:px-4 sm:py-2'
                onClick={() => setShowForm(true)}
              >
                {' '}
                <div className='flex items-center'>
                  <span className='font-inter text-left text-base text-sm font-semibold'>
                    + New department
                  </span>
                </div>
              </button>
            </div>
          )}
        </div>

        {/* Divider between title and table */}
        <div className='mb-3 border-t border-[#EAECF0]'></div>
        {!showForm && (
          <>
            <div className='flex w-full items-center justify-end'>
              <SearchBar
                placeholder='Search...'
                onSubmit={handleSearchSubmit}
              />
            </div>
            <div className='m-2 rounded-[10px] border-[1px] border-solid border-[#EAECF0]'>
              <DataTable
                columns={departmentColumns(actions)}
                data={(listDepartments?.data as any) ?? []}
                pagination={true}
                paginationPerPage={5}
                fixedHeader={true}
                customStyles={customStyles}
              />
            </div>
          </>
        )}
        {showForm && (
          <DepartmentForm
            initialValues={initialValues}
            handleCloseForm={handleCloseForm}
          />
        )}
      </div>
    </>
  );
}
